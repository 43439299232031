body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  background: url("images/logo_1085_256.png");
  background-size: 130px, 27px;
  width: 130px;
  height: 27px;
  /*background: rgba(0, 0, 0, 0.2);*/
  margin: 17px 10px 16px 20px;
  float: left;
}

.ant-table.ant-table-middle .ant-table-title, .ant-table.ant-table-middle .ant-table-footer, .ant-table.ant-table-middle thead > tr > th, .ant-table.ant-table-middle tbody > tr > td {
  padding: 1px 8px !important;
}

.ant-list-sm .ant-list-item {
  padding: 2px !important;
}

.ant-layout-header {
  background-color: white !important;
}
